import { EmailLoginResponse } from '@workerbase/api/http/auth';

export const mapSSOQueryParamsToUser = (query: URLSearchParams): EmailLoginResponse => ({
  isAiEnabled: query.get('isAiEnabled') === 'true',
  isConnectedWorkerEnabled: query.get('isConnectedWorkerEnabled') === 'true',
  isGDriveEnabled: query.get('isGDriveEnabled') === 'true',
  isHideProfilePictureEnabled: query.get('isHideProfilePictureEnabled') === 'true',
  isMyWorkEnabled: query.get('isMyWorkEnabled') === 'true',
  isOnPremise: query.get('isOnPremise') === 'true',
  isServerlessEnabled: query.get('isServerlessEnabled') === 'true',
  isWorkbenchEnabled: query.get('isWorkbenchEnabled') === 'true',
  loginToken: query.get('loginToken') ?? '',
  refreshToken: query.get('refreshToken') ?? '',
  tokenTTLInMs: Number.parseInt(query.get('tokenTTLInMs') ?? '0', 10),
  updatePIN: query.get('updatePIN') === 'true',
  user: JSON.parse(query.get('user') ?? '{}'),
});
